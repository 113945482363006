import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'

import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
// import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import GraphChart from './GraphChart'

import '../../../node_modules/react-vis/dist/style.css'
import { XYPlot, HorizontalBarSeries } from 'react-vis'

import { renderMidSubject } from '../utils/try-convert'
import PrintButton from '../utils/print'

const GET_ENTRANCE = gql`
  query store($code: ID!, $id: ID!) {
    store(code: $code){
      code
      user(id: $id) {
        id
        midSchDetail {
          entranceExamModeSwitchedAt
        }
        highSchEntranceExamLearningLogs {
          completedUnitTotalCount
          remainingPace
          targetedAt
          id
        }
        entranceExamLearningStatus {
          deadline
          targetTotalCount
          passedUnitCount
          progresses {
            subjectId
            totalCount
            passedCount
            reviewedCount
          }
        }
      }
    }
  }
`

class EntranceExam extends Component {
  state = {
    store: [],
    code: this.props.code,
    id: this.props.id,
    isEntranceExam: true
  }

  render () {
    const { code, id } = this.props

    return (
      <Query query={GET_ENTRANCE} variables={{ code, id }}>
        {({ data, loading, error }) => {
          if (loading) return <p>入試対策の情報を取得中...</p>
          if (error) return <p>エラーが発生しました。システム管理者にお問い合せください。</p>

          if (data.store.user.midSchDetail.entranceExamModeSwitchedAt === null) return <Box my={5} mx={2}><p>入試対策モードのデータが存在しません。</p></Box>

          const startedAt = data.store.user.midSchDetail.entranceExamModeSwitchedAt
          const deadlineAt = data.store.user.entranceExamLearningStatus === null ? '' : data.store.user.entranceExamLearningStatus.deadline
          const learningLogs = data.store.user.highSchEntranceExamLearningLogs
          const targetTotal = data.store.user.entranceExamLearningStatus.targetTotalCount

          // eslint-disable-next-line
          {/* const lastWeekStats = !learningLogs[1] && !learningLogs[0] ? 0 : learningLogs[0].completedUnitTotalCount - learningLogs[1].completedUnitTotalCount */}
          // eslint-disable-next-line
          {/* const lastWeekPace = !learningLogs[1] || !learningLogs[0] ? 0 : learningLogs[0].remainingPace */}

          const totalCount = data.store.user.entranceExamLearningStatus.progresses.reduce((p, x) => p + x.totalCount, 0)
          const totalPassedCount = data.store.user.entranceExamLearningStatus.progresses.reduce((p, x) => p + x.passedCount, 0)
          const passedUnitCount = data.store.user.entranceExamLearningStatus.passedUnitCount

          const totalResult = totalPassedCount / totalCount
          const totalPercentage = Math.floor(totalResult * 100)

          const totalReviewedCount = data.store.user.entranceExamLearningStatus.progresses.reduce((p, x) => p + x.reviewedCount, 0)

          const baseLogs = [{date: startedAt, value: 0}, {date: deadlineAt, value: targetTotal}, ]
          const graphLogs = learningLogs.map(log => ({'date': log.targetedAt, 'value2': log.completedUnitTotalCount}))
          const mixLogs = baseLogs.concat(graphLogs)

          console.log(data)
          console.log(baseLogs)
          console.log(graphLogs)
          console.log(mixLogs)

          return (
            <>
              <PrintButton />
              <Box my={5} mx={2}>
                <Grid container direction="row" justify="space-around" alignItems="center">
                  <Grid item>
                    <p>期日</p>
                    <Typography variant="h3" component="h2" gutterBottom>
                      {!data.store.user.entranceExamLearningStatus || !data.store.user.entranceExamLearningStatus.deadline ? '--' : `${format(data.store.user.entranceExamLearningStatus.deadline, 'MM', {locale: ja})}`} / {!data.store.user.entranceExamLearningStatus || !data.store.user.entranceExamLearningStatus.deadline ? '--' : `${format(data.store.user.entranceExamLearningStatus.deadline, 'DD', {locale: ja})}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <p>進捗</p>
                    <Typography variant="h3" component="h2" gutterBottom>
                      {learningLogs.length === 0 || data.store.user.entranceExamLearningStatus === null || !data.store.user.entranceExamLearningStatus.progresses.length ? '--' : `${passedUnitCount - learningLogs[0].completedUnitTotalCount - learningLogs[0].remainingPace}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <p>昨日までの完了単元</p>
                    <Typography variant="h3" component="h2" gutterBottom>
                      {!data.store.user.entranceExamLearningStatus || !data.store.user.entranceExamLearningStatus.progresses.length ? '--' : `${passedUnitCount}`} / {!data.store.user.entranceExamLearningStatus || !data.store.user.entranceExamLearningStatus.targetTotalCount ? '--' : `${data.store.user.entranceExamLearningStatus.targetTotalCount}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <p>先週の完了単元</p>
                    <Typography variant="h3" component="h2" gutterBottom>
                    {!learningLogs[0] && !learningLogs[1] && `-- / --`}
                    {learningLogs[0] && !learningLogs[1] && `${learningLogs[0].completedUnitTotalCount} / ${learningLogs[0].remainingPace}`}
                    {learningLogs[1] && `${learningLogs[0].completedUnitTotalCount - learningLogs[1].completedUnitTotalCount} / ${learningLogs[0].remainingPace}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <p>復習が必要な単元</p>
                    <Typography variant="h3" component="h2" gutterBottom>
                      {!data.store.user.entranceExamLearningStatus || !data.store.user.entranceExamLearningStatus.progresses.length ? '--' : `${totalReviewedCount}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <hr/>
              <Box my={5} mx={2}>
                <Typography variant="h6" component="h1" gutterBottom>
                  先週末までの進捗状況
                </Typography>
                <GraphChart lineData={mixLogs} />
              </Box>
              <Box my={10} mx={2}>
                <Typography variant="h6" component="h1" gutterBottom>
                  科目別の進捗状況
                </Typography>
                <p><small>全教科総合ならびに受講科目別の進捗です。苦手としている科目があれば積極的に声がけをしてください</small></p>
              </Box>
              <Box my={5} mx={2}>
                <Paper>
                  <Table>
                    <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row"><strong>総合</strong></TableCell>
                          <TableCell align="left">
                            <XYPlot width={960} height={10}
                              xDomain={[ 0, `${totalCount}` ]}
                              yDomain={[ 0, 1 ]}
                            >
                              <HorizontalBarSeries
                                color={null}
                                data={[
                                  { x: 0,  y: 0 },
                                  { x: `${totalPassedCount}`, y: 1 },
                                ]}
                                fill="#2196f3"
                                opacity={1}
                                stroke="#12939a"
                                style={{}}
                              />
                            </XYPlot>
                          </TableCell>
                          <TableCell align="right">{totalPercentage} %</TableCell>
                        </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </Box>
              <Box my={5} mx={2}>
                <Paper>
                  <Table>
                    <TableBody>
                      {data.store.user.entranceExamLearningStatus.progresses.map(result => (
                        <TableRow>
                          <TableCell component="th" scope="row"><strong>{renderMidSubject(result.subjectId)}</strong></TableCell>
                          <TableCell align="left">
                            <XYPlot width={960} height={10}
                              xDomain={[ 0, `${result.totalCount}` ]}
                              yDomain={[ 0, 1 ]}
                              stackBy="x"
                            >
                              <HorizontalBarSeries
                                color={null}
                                data={[
                                  { x: 0,  y: 0 },
                                  { x: `${result.passedCount}` / `${result.totalCount}` * 10, y: 1 },
                                ]}
                                fill="#29b6f6"
                                opacity={1}
                                stroke="#12939a"
                                style={{}}
                              />
                              <HorizontalBarSeries
                                color={null}
                                data={[
                                  { x: 0,  y: 0 },
                                  { x: `${result.reviewedCount}` / `${result.totalCount}` * 10, y: 1 },
                                ]}
                                fill="#cc0000"
                                opacity={1}
                                stroke="#990000"
                                style={{}}
                              />
                            </XYPlot>
                          </TableCell>
                          <TableCell align="right">{result.passedCount}/{result.totalCount}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Box>
            </>
          )
        }}
      </Query>
    )
  }

}

EntranceExam.propTypes = {
  id: PropTypes.number.isRequired,
  user: PropTypes.number.isRequired,
  code: PropTypes.number.isRequired,
}

export default EntranceExam
