import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import { ButtonGroup } from '@material-ui/core'

class JsHsButton extends Component {
  state = {
    officeCode: this.props.officeCode
  }

  render() {
    return (
      <>
        <ButtonGroup>
          <Button variant="contained" color="primary" size="small" href={`/venue/${this.state.officeCode}`}>
            <Icon>cached</Icon>&nbsp;中学版&nbsp;
          </Button>
          {` `}
          <Button variant="outlined" color="default" size="small" href={`https://dev.h-admin.ai.trygroup.co.jp/venue/${this.state.officeCode}`} target="_blank">
            <Icon>launch</Icon>&nbsp;高校版&nbsp;
          </Button>
        </ButtonGroup>
      </>
    )
  }
}

export default JsHsButton
