import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { LineChart } from 'react-chartkick'
import 'chart.js'

import { renderRegularExam, renderSubjectCategory, renderMidSubject } from '../utils/try-convert'
import { fiscalYear } from '../utils/fiscalyear'
import PrintButton from '../utils/print'

const GET_REGULAR = gql`
  query store($code: ID!, $id: ID!) {
    store(code: $code){
      code
      user(id: $id) {
        id
        gradeCode
        midSchRegularExams {
          id
          gradeCode
          startedOn
          endedOn
          regularExamLabelCode
          totalScore
          midSchRegularExamScores {
            subjectCategoryId
            score
            unexamined
          }
          midSchRegularExamTasks {
            midSchNormalSubjectId
            excluded
            understandingLevel
          }
        }
      }
    }
  }
`

class RegularExam extends Component {
  state = {
    store: [],
    code: this.props.code,
    id: this.props.id,
    isRegularExam: true
  }

  render () {
    const { code, id } = this.props

    return (
      <Query query={GET_REGULAR} variables={{ code, id }}>
        {({ data, loading, error }) => {
          if (loading) return <p>テスト対策の情報を取得中...</p>
          if (error) return <p>エラーが発生しました。システム管理者にお問い合せください。</p>

          if (data.store.user.midSchRegularExams.length === 0) return <Box my={5} mx={2}><p>定期テスト対策のデータが存在しません。</p></Box>

          const learningLogs = data.store.user.midSchRegularExams

          const groupGrade = learningLogs.reduce((result, current) => {
            const element = result.find((p) => {
              return p.gradeCode === current.gradeCode
            })
            if (element) {
              element.periods.push({
                regularExamLabelCode: current.regularExamLabelCode,
                midSchRegularExamScores: current.midSchRegularExamScores,
                midSchRegularExamTasks: current.midSchRegularExamTasks,
                totalScore: current.totalScore
              })
            } else {
              result.push({
                gradeCode: current.gradeCode,
                startedOn: current.startedOn,
                endedOn: current.endedOn,
                periods: [
                  {
                    regularExamLabelCode: current.regularExamLabelCode,
                    midSchRegularExamScores: current.midSchRegularExamScores,
                    midSchRegularExamTasks: current.midSchRegularExamTasks,
                    totalScore: current.totalScore
                  }
                ]
              });
            }
            return result

          },[])

          const sortSubjectCategoryId = function (a, b) {
            return a.subjectCategoryId - b.subjectCategoryId
          }

          const sortSubjectId = function (a, b) {
            return a.midSchNormalSubjectId - b.midSchNormalSubjectId
          }

          const groupGradeId = function (a, b) {
            return b.gradeCode - a.gradeCode
          }

          console.log(data)
          console.log(learningLogs)
          console.log(groupGrade)
          const groupResult = groupGrade.sort(groupGradeId)

          return (
            <>
              <PrintButton />
              <Box my={5} >
              {groupResult[0].gradeCode === data.store.user.gradeCode &&
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>{fiscalYear()}年度</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container spacing={3} alignItems="flex-start">
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h1" gutterBottom>
                          定期テストの結果（合計点の推移）
                        </Typography>
                        <LineChart
                          width="100%"
                          height="240px"
                          xmin={fiscalYear() + "-05-01"}
                          xmax={fiscalYear() +1 + "-03-31"}
                          legend={false}
                          max={500}
                          data={groupResult[0].periods.map(log => [renderRegularExam(log.regularExamLabelCode), log.totalScore])}
                          curve={false}
                        />
                      </Grid>
                      <hr/>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h1" gutterBottom>
                          定期テストの結果（科目別の点数）
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper>
                          <Table>
                            <TableBody>
                              {groupResult[0].periods.map(exam => (
                              <>
                                <TableRow key={exam.id}>
                                  <TableCell component="th" scope="row" rowSpan="2" width="200">{renderRegularExam(exam.regularExamLabelCode)}</TableCell>
                                  {exam.midSchRegularExamScores.sort(sortSubjectCategoryId).map(result => (
                                    <TableCell align="center">{renderSubjectCategory(result.subjectCategoryId)}</TableCell>
                                  ))}
                                </TableRow>
                                <TableRow>
                                  {exam.midSchRegularExamScores.sort(sortSubjectCategoryId).map(result => (
                                    <TableCell align="center">{result.score === null ? '--' : `${result.score}`}</TableCell>
                                  ))}
                                </TableRow>
                              </>
                              ))}
                            </TableBody>
                          </Table>
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h1" gutterBottom>
                          定期テスト対策の学習結果
                        </Typography>
                      {groupResult[0].periods.map(exam => (
                        <Box my={5} mx={2} key={exam.id}>
                          <p>
                            {renderRegularExam(exam.regularExamLabelCode)}
                          </p>
                          <Paper>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  {exam.midSchRegularExamTasks.sort(sortSubjectId).map(result => (
                                    <TableCell component="th" scope="col" align="center">{renderMidSubject(result.midSchNormalSubjectId)}</TableCell>
                                  ))}
                                </TableRow>
                                <TableRow>
                                  {exam.midSchRegularExamTasks.sort(sortSubjectId).map(result => (
                                    <TableCell align="center">{result.excluded === true ? '--' : `${result.understandingLevel}`}</TableCell>
                                  ))}
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Paper>
                        </Box>
                      ))}
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>}
              {groupResult[0].gradeCode !== data.store.user.gradeCode &&
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>{fiscalYear() - 1}年度</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container spacing={3} alignItems="flex-start">
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h1" gutterBottom>
                          定期テストの結果（合計点の推移）
                        </Typography>
                        <LineChart
                          width="100%"
                          height="240px"
                          xmin={fiscalYear() -1 + "-05-01"}
                          xmax={fiscalYear() + "-03-31"}
                          legend={false}
                          max={500}
                          data={groupResult[0].periods.map(log => [renderRegularExam(log.regularExamLabelCode), log.totalScore])}
                          curve={false}
                        />
                      </Grid>
                      <hr/>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h1" gutterBottom>
                          定期テストの結果（科目別の点数）
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper>
                          <Table>
                            <TableBody>
                              {groupResult[0].periods.map(exam => (
                              <>
                                <TableRow key={exam.id}>
                                  <TableCell component="th" scope="row" rowSpan="2" width="200">{renderRegularExam(exam.regularExamLabelCode)}</TableCell>
                                  {exam.midSchRegularExamScores.sort(sortSubjectCategoryId).map(result => (
                                    <TableCell align="center">{renderSubjectCategory(result.subjectCategoryId)}</TableCell>
                                  ))}
                                </TableRow>
                                <TableRow>
                                  {exam.midSchRegularExamScores.sort(sortSubjectCategoryId).map(result => (
                                    <TableCell align="center">{result.score === null ? '--' : `${result.score}`}</TableCell>
                                  ))}
                                </TableRow>
                              </>
                              ))}
                            </TableBody>
                          </Table>
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h1" gutterBottom>
                          定期テスト対策の学習結果
                        </Typography>
                      {groupResult[0].periods.map(exam => (
                        <Box my={5} mx={2} key={exam.id}>
                          <p>
                            {renderRegularExam(exam.regularExamLabelCode)}
                          </p>
                          <Paper>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  {exam.midSchRegularExamTasks.sort(sortSubjectId).map(result => (
                                    <TableCell component="th" scope="col" align="center">{renderMidSubject(result.midSchNormalSubjectId)}</TableCell>
                                  ))}
                                </TableRow>
                                <TableRow>
                                  {exam.midSchRegularExamTasks.sort(sortSubjectId).map(result => (
                                    <TableCell align="center">{result.excluded === true ? '--' : `${result.understandingLevel}`}</TableCell>
                                  ))}
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Paper>
                        </Box>
                      ))}
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>}
              {groupResult[1] !== undefined && groupResult[1].gradeCode !== data.store.user.gradeCode &&
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>{fiscalYear() - 1}年度</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container spacing={3} alignItems="flex-start">
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h1" gutterBottom>
                          定期テストの結果（合計点の推移）
                        </Typography>
                        <LineChart
                          width="100%"
                          height="240px"
                          xmin={fiscalYear() -1 + "-05-01"}
                          xmax={fiscalYear() + "-03-31"}
                          legend={false}
                          max={500}
                          data={groupResult[1].periods.map(log => [renderRegularExam(log.regularExamLabelCode), log.totalScore])}
                          curve={false}
                        />
                      </Grid>
                      <hr/>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h1" gutterBottom>
                          定期テストの結果（科目別の点数）
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper>
                          <Table>
                            <TableBody>
                              {groupResult[1].periods.map(exam => (
                              <>
                                <TableRow key={exam.id}>
                                  <TableCell component="th" scope="row" rowSpan="2" width="200">{renderRegularExam(exam.regularExamLabelCode)}</TableCell>
                                  {exam.midSchRegularExamScores.sort(sortSubjectCategoryId).map(result => (
                                    <TableCell align="center">{renderSubjectCategory(result.subjectCategoryId)}</TableCell>
                                  ))}
                                </TableRow>
                                <TableRow>
                                  {exam.midSchRegularExamScores.sort(sortSubjectCategoryId).map(result => (
                                    <TableCell align="center">{result.score === null ? '--' : `${result.score}`}</TableCell>
                                  ))}
                                </TableRow>
                              </>
                              ))}
                            </TableBody>
                          </Table>
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h1" gutterBottom>
                          定期テスト対策の学習結果
                        </Typography>
                      {groupResult[1].periods.map(exam => (
                        <Box my={5} mx={2} key={exam.id}>
                          <p>
                            {renderRegularExam(exam.regularExamLabelCode)}
                          </p>
                          <Paper>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  {exam.midSchRegularExamTasks.sort(sortSubjectId).map(result => (
                                    <TableCell component="th" scope="col" align="center">{renderMidSubject(result.midSchNormalSubjectId)}</TableCell>
                                  ))}
                                </TableRow>
                                <TableRow>
                                  {exam.midSchRegularExamTasks.sort(sortSubjectId).map(result => (
                                    <TableCell align="center">{result.excluded === true ? '--' : `${result.understandingLevel}`}</TableCell>
                                  ))}
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Paper>
                        </Box>
                      ))}
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>}
              {groupResult[2] !== undefined && groupResult[2].gradeCode !== data.store.user.gradeCode &&
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>{fiscalYear() - 2}年度</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container spacing={3} alignItems="flex-start">
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h1" gutterBottom>
                          定期テストの結果（合計点の推移）
                        </Typography>
                        <LineChart
                          width="100%"
                          height="240px"
                          xmin={fiscalYear() -2 + "-05-01"}
                          xmax={fiscalYear() -1 + "-03-31"}
                          legend={false}
                          max={500}
                          data={groupResult[2].periods.map(log => [renderRegularExam(log.regularExamLabelCode), log.totalScore])}
                          curve={false}
                        />
                      </Grid>
                      <hr/>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h1" gutterBottom>
                          定期テストの結果（科目別の点数）
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper>
                          <Table>
                            <TableBody>
                              {groupResult[2].periods.map(exam => (
                              <>
                                <TableRow key={exam.id}>
                                  <TableCell component="th" scope="row" rowSpan="2" width="200">{renderRegularExam(exam.regularExamLabelCode)}</TableCell>
                                  {exam.midSchRegularExamScores.sort(sortSubjectCategoryId).map(result => (
                                    <TableCell align="center">{renderSubjectCategory(result.subjectCategoryId)}</TableCell>
                                  ))}
                                </TableRow>
                                <TableRow>
                                  {exam.midSchRegularExamScores.sort(sortSubjectCategoryId).map(result => (
                                    <TableCell align="center">{result.score === null ? '--' : `${result.score}`}</TableCell>
                                  ))}
                                </TableRow>
                              </>
                              ))}
                            </TableBody>
                          </Table>
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h1" gutterBottom>
                          定期テスト対策の学習結果
                        </Typography>
                      {groupResult[2].periods.map(exam => (
                        <Box my={5} mx={2} key={exam.id}>
                          <p>
                            {renderRegularExam(exam.regularExamLabelCode)}
                          </p>
                          <Paper>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  {exam.midSchRegularExamTasks.sort(sortSubjectId).map(result => (
                                    <TableCell component="th" scope="col" align="center">{renderMidSubject(result.midSchNormalSubjectId)}</TableCell>
                                  ))}
                                </TableRow>
                                <TableRow>
                                  {exam.midSchRegularExamTasks.sort(sortSubjectId).map(result => (
                                    <TableCell align="center">{result.excluded === true ? '--' : `${result.understandingLevel}`}</TableCell>
                                  ))}
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Paper>
                        </Box>
                      ))}
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>}
              </Box>
            </>
          )
        }}
      </Query>
    )
  }

}

RegularExam.propTypes = {
  id: PropTypes.number.isRequired,
  user: PropTypes.number.isRequired,
  code: PropTypes.number.isRequired,
}

export default RegularExam
