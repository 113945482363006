/* eslint-disable */
require('dotenv').config()

import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.css'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import App from './components/App'
import theme from './theme'
import { BrowserRouter } from 'react-router-dom'

import * as serviceWorker from './serviceWorker'

import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { AUTH_TOKEN } from './constants'

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(AUTH_TOKEN)
  // return the headers to the context so httpLink can read them
  return {
    fetchOptions: {
      credentials: 'include'
    },
    headers: {
      ...headers,
      Authorization: token ? `Token ${token}` : '',
      // Cookie: `_ashigaru_backend_session=b1cde8e05f7dd962ddedecc629df1253`
    }
  }
})

const httpLink = createHttpLink({
  // uri: `${process.env.REACT_APP_GRAPHQL_API}`,
  uri: `https://dev.backend.ai.trygroup.co.jp/admin/graphql`,
  withCredentials: true
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
