import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'

import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
// import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import GraphChart from './GraphChart'

import '../../../node_modules/react-vis/dist/style.css'
import { XYPlot, HorizontalBarSeries } from 'react-vis'

import { renderMidSubject } from '../utils/try-convert'
import PrintButton from '../utils/print'
import BasicButton from './BasicButton'

const GET_BASIC = gql`
  query store($code: ID!, $id: ID!) {
    store(code: $code){
      code
      user(id: $id) {
        id
        midSchDetail {
          basicModeSwitchedAt
        }
        midSchBasicLearningLogs {
          completedUnitTotalCount
          remainingPace
          id
          targetedAt
        }
        basicLearningStatus {
          deadline
          targetTotalCount
          progresses {
            subjectId
            totalCount
            passedCount
          }
        }
        midSchCompletionExams {
          midSchNormalSubjectId
          totalUnitCount
          goodUnitCount
          updatedAt
          understandingLevel
        }
        midSchCompletionExamFileUrl
      }
    }
  }
`

class Basic extends Component {
  state = {
    store: [],
    code: this.props.code,
    id: this.props.id,
    isBasic: true
  }

  render () {
    const { code, id } = this.props

    return (
      <Query query={GET_BASIC} variables={{ code, id }}>
        {({ data, loading, error }) => {
          if (loading) return <p>基礎固めの情報を取得中...</p>
          if (error) return <p>エラーが発生しました。システム管理者にお問い合せください。</p>

          if (data.store.user.midSchDetail.basicModeSwitchedAt === null) return <Box my={5} mx={2}><p>基礎固めモードのデータが存在しません。</p></Box>

          const startedAt = data.store.user.midSchDetail.basicModeSwitchedAt
          const deadlineAt = data.store.user.basicLearningStatus === null ? '' : data.store.user.basicLearningStatus.deadline
          const learningLogs = data.store.user.midSchBasicLearningLogs
          // eslint-disable-next-line
          {/* const lastWeekStats = !learningLogs[1] && !learningLogs[0] && `0` || !learningLogs[1] && learningLogs[0].completedUnitTotalCount || learningLogs[0].completedUnitTotalCount - learningLogs[1].completedUnitTotalCount */}
          // eslint-disable-next-line
          const lastWeekClear = !learningLogs[1] && !learningLogs[0] && `0` || !learningLogs[1] && learningLogs[0].completedUnitTotalCount || learningLogs[0].completedUnitTotalCount
          // eslint-disable-next-line
          {/* const lastWeekPace = !learningLogs[1] || !learningLogs[0] ? 0 : learningLogs[0].remainingPace */}

          const totalCount = data.store.user.basicLearningStatus.progresses.reduce((p, x) => p + x.totalCount, 0)
          const totalPassedCount = data.store.user.basicLearningStatus.progresses.reduce((p, x) => p + x.passedCount, 0)

          const totalResult = totalPassedCount / totalCount
          const totalPercentage = Math.floor(totalResult * 100)

          const baseLogs = [{date: startedAt, value: 0}, {date: deadlineAt, value: 150}, ]
          const graphLogs = learningLogs.map(log => ({'date': log.targetedAt, 'value2': log.completedUnitTotalCount}))
          const mixLogs = baseLogs.concat(graphLogs)

          console.log(data)
          console.log(baseLogs)
          console.log(graphLogs)
          console.log(mixLogs)

          const sortSubjectId = function (a, b) {
            return a.midSchNormalSubjectId - b.midSchNormalSubjectId
          }

          return (
            <>
              <PrintButton />
              <Box my={5} mx={2}>
                <Grid container direction="row" justify="space-around" alignItems="center">
                  <Grid item>
                    <p>期日</p>
                    <Typography variant="h3" component="h2" gutterBottom>
                      {!data.store.user.basicLearningStatus || !data.store.user.basicLearningStatus.deadline ? '--' : `${format(data.store.user.basicLearningStatus.deadline, 'MM', {locale: ja})}`} / {!data.store.user.basicLearningStatus || !data.store.user.basicLearningStatus.deadline ? '--' : `${format(data.store.user.basicLearningStatus.deadline, 'DD', {locale: ja})}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <p>進捗</p>
                    <Typography variant="h3" component="h2" gutterBottom>
                      {data.store.user.basicLearningStatus === null || !data.store.user.basicLearningStatus.progresses.length ? '--' : `${totalPassedCount}` - `${lastWeekClear}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <p>現在までの完了単元</p>
                    <Typography variant="h3" component="h2" gutterBottom>
                      {data.store.user.basicLearningStatus === null || !data.store.user.basicLearningStatus.progresses.length ? '--' : `${totalPassedCount}`} / {data.store.user.basicLearningStatus === null || !data.store.user.basicLearningStatus.targetTotalCount ? '--' : `${data.store.user.basicLearningStatus.targetTotalCount}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <p>先週の完了単元</p>
                    <Typography variant="h3" component="h2" gutterBottom>
                    {!learningLogs[0] && !learningLogs[1] && `-- / --`}
                    {learningLogs[0] && !learningLogs[1] && `${learningLogs[0].completedUnitTotalCount} / ${learningLogs[0].remainingPace}`}
                    {learningLogs[1] && `${learningLogs[0].completedUnitTotalCount - learningLogs[1].completedUnitTotalCount} / ${learningLogs[0].remainingPace}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <hr/>
              <Box my={5} mx={2}>
                <Typography variant="h6" component="h1" gutterBottom>
                  先週末までの進捗状況
                </Typography>
                <GraphChart lineData={mixLogs} />
              </Box>
              <Box my={10} mx={2}>
                <Typography variant="h6" component="h1" gutterBottom>
                  科目別の進捗状況
                </Typography>
                <p><small>全教科総合ならびに受講科目別の進捗です。苦手としている科目があれば積極的に声がけをしてください</small></p>
              </Box>
              <Box my={5} mx={2}>
                <Paper>
                  <Table>
                    <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row"><strong>総合</strong></TableCell>
                          <TableCell align="left">
                            <XYPlot width={960} height={10}
                              xDomain={[ 0, `${totalCount}` ]}
                              yDomain={[ 0, 1 ]}
                            >
                              <HorizontalBarSeries
                                color={null}
                                data={[
                                  { x: 0,  y: 0 },
                                  { x: `${totalPassedCount}`, y: 1 },
                                ]}
                                fill="#2196f3"
                                opacity={1}
                                stroke="#12939a"
                                style={{}}
                              />
                            </XYPlot>
                          </TableCell>
                          <TableCell align="right">{totalPercentage} %</TableCell>
                        </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </Box>
              <Box my={5} mx={2}>
                <Paper>
                  <Table>
                    <TableBody>
                      {data.store.user.basicLearningStatus.progresses.map(result => (
                        <TableRow key={result.subjectId}>
                          <TableCell component="th" scope="row"><strong>{renderMidSubject(result.subjectId)}</strong></TableCell>
                          <TableCell align="left">
                            <XYPlot width={960} height={10}
                              xDomain={[ 0, `${result.totalCount}` ]}
                              yDomain={[ 0, 1 ]}
                            >
                              <HorizontalBarSeries
                                color={null}
                                data={[
                                  { x: 0,  y: 0 },
                                  { x: `${result.passedCount}`, y: 1 },
                                ]}
                                fill="#29b6f6"
                                opacity={1}
                                stroke="#12939a"
                                style={{}}
                              />
                            </XYPlot>
                          </TableCell>
                          <TableCell align="right">{result.passedCount}/{result.totalCount}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Box>
              <Box my={5} mx={2}>
                <Typography variant="h6" component="h1" gutterBottom>
                  修了テスト　{data.store.user.midSchCompletionExamFileUrl === null ? '' : <BasicButton align="right" user={data.store.user.id} code={data.store.code} />}
                </Typography>
              </Box>
              <Box my={5} mx={2}>
                <Paper>
                  <Table>
                    <TableBody>
                      <TableRow>
                        {data.store.user.midSchCompletionExams.sort(sortSubjectId).map(result => (
                          <TableCell align="center">{renderMidSubject(result.midSchNormalSubjectId)}</TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        {data.store.user.midSchCompletionExams.map(result => (
                          <TableCell align="center">{result.goodUnitCount} / {result.totalUnitCount}</TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </Box>
            </>
          )
        }}
      </Query>
    )
  }

}

Basic.propTypes = {
  id: PropTypes.number.isRequired,
  code: PropTypes.number.isRequired,
}

export default Basic
